export function AudioQueue(audioElement) {
  let playlist = []
  let currentTrack = 0

  this.play = function (src) {
    playlist.push(src)
    if (audioElement.paused) {
      audioElement.src = playlist[currentTrack]
      audioElement.play()
    }
  }

  const __playNextTrack = () => {
    currentTrack++

    if (currentTrack < playlist.length) {
      audioElement.src = playlist[currentTrack]
      audioElement.play()
    }
  }

  audioElement.addEventListener('ended', __playNextTrack)
}
