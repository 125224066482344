import { motion } from 'framer-motion'
import { createRef, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RotatingLines } from 'react-loader-spinner'
import { useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import './Player.css'
import { ErrNoTranslation, fetchTranslatorRooms, postLogs } from './axios/requests'
import { InfoModal } from './components/modals/info'
import img_primary from './images/primary.png'
import infoBtn from './images/v1/info_white_24.svg'
import bars from './images/v1/ver_bars_24.svg'
import logo from './images/v1/vox_logo_white.svg'
import { EnvFlagsContext } from './index'
import './index.css'

const JoinStream = (props) => {
  const ip1 = createRef()
  const ip2 = createRef()
  const ip3 = createRef()
  const ip4 = createRef()
  const ip5 = createRef()
  const ip6 = createRef()

  const history = useHistory()
  const isProd = useRef(useContext(EnvFlagsContext).isProd)
  // init translations
  const { t, i18n } = useTranslation()
  useEffect(() => {
    const lng = navigator.language
    i18n.changeLanguage(lng)
  }, [])

  const [state, setState] = useState({
    streamName: '',
    showInfo: false,
    streamId: null,
  })

  const [isLoading, setIsLoading] = useState(false)

  // streamId will never change
  // rendered only once, at app mount
  useEffect(() => {
    let stream = props.match.params.streamId
    setState((prevState) => {
      return {
        ...prevState,
        streamId: stream,
      }
    })
  }, [])

  const spinner = () => {
    console.log('Loading Spinner')
    return (
      <header className='App-header'>
        <RotatingLines
          strokeColor='grey'
          strokeWidth='5'
          animationDuration='0.75'
          width='96'
          visible={true}
        />
      </header>
    )
  }

  const playStream = (streamId, image) => {
    sessionStorage.setItem('SelectedStream', streamId.toUpperCase())
    sessionStorage.setItem('LangFlag', image)
    sessionStorage.setItem('TranslatorLangId', 'live')
    sessionStorage.setItem('LangId', 'undefined')
    sessionStorage.setItem('LangName', 'Live')
    sessionStorage.setItem('HasTranslation', 'no')
    sessionStorage.setItem('ShowCaptions', true) // stream.name !== 'Live')

    history.push('/play')
  }

  const pathChooser = (streamId) => {
    if (streamId === null) {
      console.log('streamId is null. cannot proceed')
      return
    }
    setIsLoading(true)
    fetchTranslatorRooms(streamId, isProd.current)
      .then((response) => {
        const popmap_Id = response.data.popmap_id
        const translation_rooms = response.data.translation_rooms
        const auto_translations = response.data.auto_translations

        joinRoomEventLog(streamId.toUpperCase(), sessionStorage.getItem('VoxConnectListenerId'))

        if (popmap_Id === null && auto_translations === false && translation_rooms.length === 0) {
          playStream(streamId, img_primary)
          setIsLoading(false)
        } else {
          setIsLoading(false)
          history.push('/select-translator')
        }
      })
      .catch((error) => {
        if (error.message === ErrNoTranslation.message) {
          playStream(streamId, img_primary)
        }
        console.log('failure in choosing path:' + error)
        setIsLoading(false)
      })
  }

  const streamChangeHandler = ({ target: { value } }) => {
    setState((prevState) => {
      return {
        ...prevState,
        streamName: value,
      }
    })
  }

  const handleInfoModalToggle = () => {
    setState((prevState) => {
      return {
        ...prevState,
        showInfo: !prevState.showInfo,
      }
    })
  }
  const { streamName, streamId } = state

  const handleClick = () => {
    let name = ''
    if (streamId && streamId !== '') {
      name = streamId
    } else {
      name =
        ip1.current.value +
        ip2.current.value +
        ip3.current.value +
        '-' +
        ip4.current.value +
        ip5.current.value +
        ip6.current.value
    }

    // FIXME: This condition seems redundant
    if (streamId != null) {
      sessionStorage.setItem(
        'StreamName',
        name.toUpperCase(),
        // streamId.toUpperCase(),
      )
      // sessionStorage.setItem("SelectedStream", name.toUpperCase());
    } else {
      sessionStorage.setItem(
        'StreamName',
        name.toUpperCase(),
        // streamName.toUpperCase(),
      )
      // sessionStorage.setItem("SelectedStream", name.toUpperCase());
    }

    // Set the Listener Id
    const listenerId = sessionStorage.getItem('VoxConnectListenerId')
    if (listenerId === null) {
      sessionStorage.setItem('VoxConnectListenerId', uuidv4())
    }
    const streamNameStorage = sessionStorage.getItem('StreamName')
    pathChooser(streamNameStorage)
    // props.history.replace("/select-translator");
  }

  const autoTab = (e) => {
    const BACKSPACE_KEY = 8
    const DELETE_KEY = 46
    let tabindex = e.target.getAttribute('data-index')
    tabindex = Number(tabindex)
    console.log('the taget is: ', e.target)
    // let tabindex = $(e.target).attr("data-index") || 0;
    let elem = null
    tabindex = Number(tabindex)
    // if (e.target.value === ""){
    //   return
    // }
    switch (tabindex) {
      case 1:
        if (e.keyCode === BACKSPACE_KEY) {
          elem = ip1
        } else if (e.keyCode !== DELETE_KEY) {
          elem = ip2
        }
        break
      case 2:
        if (e.keyCode === BACKSPACE_KEY) {
          elem = ip1
        } else if (e.keyCode !== DELETE_KEY) {
          elem = ip3
        }
        break
      case 3:
        if (e.keyCode === BACKSPACE_KEY) {
          elem = ip2
        } else if (e.keyCode !== DELETE_KEY) {
          elem = ip4
        }
        break
      case 4:
        if (e.keyCode === BACKSPACE_KEY) {
          elem = ip3
        } else if (e.keyCode !== DELETE_KEY) {
          elem = ip5
        }
        break
      case 5:
        if (e.keyCode === BACKSPACE_KEY) {
          elem = ip4
        } else if (e.keyCode !== DELETE_KEY) {
          elem = ip6
        }
        break
      case 6:
        if (e.keyCode === BACKSPACE_KEY) {
          elem = ip5
        } else if (e.keyCode !== DELETE_KEY) {
          elem = ip6
        }
        break
      default:
        elem = ip1
        break
    }

    if (elem) {
      elem.current.focus()
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0, x: history.action === 'POP' ? '-100%' : '100%' }}
      animate={{ opacity: 1, x: 0 }}
      // exit={{ opacity: 0, x:"100%" }}
      transition={{ ease: 'easeIn', duration: 0.1 }}
      style={{ position: 'absolute', width: '100%', height: '100%' }}
    >
      <div className='Player'>
        {/* <div className="Player-joinstream-child"> */}
        {/* <div className="Player-inner-child"> */}

        <header className='App-header'>
          <div className='logo-presenter-block'>
            <img src={logo} className='App-logo' alt='logo' />
          </div>
          <div className='player-main-body'>
            <div className='startTour'>{t('joinStreamPage.startTour')}</div>
            {streamId == null ? (
              <div className='startTourDetail'>{t('joinStreamPage.startTourDetail')}</div>
            ) : (
              <div className='startTourDetail'>{t('joinStreamPage.startTourDetailCode')}</div>
            )}
          </div>
          <div className='player-footer'>
            <div className='InputField'>
              {streamId == null ? (
                <div>
                  <input
                    type='text'
                    maxLength={1}
                    ref={ip1}
                    className='block'
                    data-index={1}
                    onKeyUp={autoTab}
                  />
                  <input
                    type='text'
                    maxLength={1}
                    ref={ip2}
                    className='block'
                    data-index={2}
                    onKeyUp={autoTab}
                  />
                  <input
                    type='text'
                    maxLength={1}
                    ref={ip3}
                    className='block margin-block'
                    data-index={3}
                    onKeyUp={autoTab}
                  />
                  <input
                    type='text'
                    maxLength={1}
                    ref={ip4}
                    className='block'
                    data-index={4}
                    onKeyUp={autoTab}
                  />
                  <input
                    type='text'
                    maxLength={1}
                    ref={ip5}
                    className='block'
                    data-index={5}
                    onKeyUp={autoTab}
                  />
                  <input
                    type='text'
                    maxLength={1}
                    ref={ip6}
                    className='block'
                    data-index={6}
                    onKeyUp={autoTab}
                  />
                </div>
              ) : (
                // <input type="text" onChange={streamChangeHandler} />
                <div className='streamIdPresent'> {streamId} </div>
              )}
            </div>
            {isLoading && spinner()}
            <div className='joinstream'>{<div className='vox-button-container'></div>}</div>
          </div>
        </header>
        {/* </div>
        </div> */}
      </div>
      <div className='infoBtnWrapper'>
        <button className='infoBtn' onClick={handleInfoModalToggle}>
          <img src={infoBtn} className='infoBtnImg' />
        </button>
      </div>
      <InfoModal show={state.showInfo} close={handleInfoModalToggle} />
      <div className='vox-button-inner-container'>
        <div onClick={handleClick} className='vox-button-group'>
          <div className='vox-button-elem'>
            <img src={bars} alt='bars' className='' />
          </div>
          <div className='vox-button-elem' style={{ marginTop: '5px' }}>
            {t('joinStreamPage.joinStream')}
          </div>
        </div>
      </div>
    </motion.div>
  )
}

const joinRoomEventLog = (streamId, listenerId) => {
  console.log('Joining Room Event Log')
  const data = {
    username: listenerId,
    timestamp: Math.round(Date.now() / 1000),
    app_version: '1.0.' + window.VERSION.slice(0, 8),
    event: 'join_stream',
    metadata: {
      stream_id: streamId,
    },
  }
  postLogs(data)
}

export default JoinStream
