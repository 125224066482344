import { envFlags } from './utils'

const TRANSCRIPTION_WS_SERVER_PROD = 'https://vconn-ts-c1d7aa0071e2.herokuapp.com/ws/'
const TRANSCRIPTION_WS_SERVER_STAGING = 'https://vconn-ts-staging-8f170d6a5d7f.herokuapp.com/ws/'
// const TRANSCRIPTION_WS_SERVER_LOCAL = 'http://192.168.0.2:8888/ws/'

export default function TranscriptionWebsocket(
  roomId,
  listenerId,
  language,
  callback,
  callbackError,
) {
  // const url = TRANSCRIPTION_WS_SERVER_LOCAL
  const url = envFlags().isProd ? TRANSCRIPTION_WS_SERVER_PROD : TRANSCRIPTION_WS_SERVER_STAGING

  const wsConn = new WebSocket(url + roomId + '/listener/' + listenerId + '/' + language)
  wsConn.binaryType = 'arraybuffer'

  const debug = true
  var connected = false

  var pingTimerId = -1

  this.close = function () {
    wsConn.close()
  }

  var clearTimers = function () {
    if (pingTimerId !== -1) {
      console.debug('Clearing ping message timer')
      clearInterval(pingTimerId)
      pingTimerId = -1
    }
  }

  var sendPing = function () {
    wsConn.send('ping')
  }

  wsConn.onopen = function () {
    if (debug) {
      console.log('websocket connected')
    }

    pingTimerId = setInterval(() => {
      sendPing()
    }, 3000)

    connected = true
    callback('initialized')
  }

  this.isConnected = function () {
    return connected
  }

  wsConn.onmessage = function (event) {
    const data = event.data

    if (data === 'ping') {
      console.log('received ping')
    }

    if (data instanceof ArrayBuffer) {
      const dataView = new DataView(data)

      const messageLength = dataView.getUint32(0, false)

      const messageBytes = new Uint8Array(data, 4, messageLength)
      const message = new TextDecoder().decode(messageBytes)

      const audio = new Uint8Array(data, 4 + messageLength)
      const audioBlob = new Blob([audio], { type: 'audio/mp3' })
      const audioUrl = URL.createObjectURL(audioBlob)

      console.log(message)
      const obj = {
        message: message,
        audioUrl: audioUrl,
      }
      callback('translation', obj)
    } else {
      console.log(event.data)
      callback('message', event.data)
    }
  }

  wsConn.onerror = function (error) {
    connected = false
    console.log(' error occured: ' + JSON.stringify(error))
    clearTimers()
    callbackError(error)
  }

  wsConn.onclose = function (event) {
    connected = false
    console.log('connection closed.')
    clearTimers()
    callback('closed', event)
  }
}
